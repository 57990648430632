import React from "react";
import { NavLink } from "react-router-dom";

const MenuDos = () => {
  return (
    <>
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
        <div className=" container-fluid px-0 px-sm-5">
        
          <img
            style={{ width: "210px" }}
            src=".//media/LogoConsulAdvisors.jpg"
            alt="ConsulAdvisors Logo"
          />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mynavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="mynavbar">
            <ul className="navbar-nav mx-auto">
              <li className=" text-center nav-item px-sm-3">
                <NavLink to="/">HOME</NavLink>
              </li>
              <li className=" text-center nav-item px-sm-3">
                <NavLink to="/us">Us</NavLink>
              </li>
              <li className=" text-center nav-item px-sm-3">
                <NavLink to="/">Contact</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MenuDos;
