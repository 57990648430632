import axios from "axios";

const base_url = "http://localhost:18000/api"+"/mail";


const send_mail = (objeto) =>{

    console.log("prueba",base_url)

    const request = axios.post(base_url, objeto);

    return request.then(response => response.data)
}


const emailService = {send_mail};

export default emailService;
