import React, { useEffect, useRef, useState } from "react";

import videoSrc from "./media/digitaltransformation480.mov";
import videoSrc2 from "./media/cityConsulAdvisors.mov";
import "./Home.css";

const Home = () => {
  const centerText = useRef(null);
  const card = useRef(null);
  const card2 = useRef(null);
  const card3 = useRef(null);
  const [mouseOverState, setMouseOverState] = useState(false);

  const mouseOverHandler = () => {
    setMouseOverState(true);
  };

  const mouseOutHandler = () => {
    setMouseOverState(false);
  };

  useEffect(() => {
    centerText.current.style.backgroundColor = "#E8E6E6";
    card.current.style.backgroundColor = "#5A8A68";
    card2.current.style.backgroundColor = "#E8E6E6";
    if (mouseOverState) {
      card3.current.style.backgroundColor = "#E8E6E6";
    }
  }, [centerText, card, card2, mouseOverState]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div
            className="imgVidHeight col-md video-container"
          >
            <video src={videoSrc} autoPlay={true} muted={true} loop={true} />
          </div>
          <div
            className="col-md bg-image-cropped"
            style={{
              backgroundImage: 'url(".//media/abstractBuildings.jpg")',
            }}
          >
            <br />
            <h1 className="text-center m-sm-5 px-sm-5">
              STRATEGIC ADVISORY SERVICES
            </h1>
            <br/>
            <p className="h3 text-center p-5">
              Maximizing long-term value for our clients
            </p>
            <br />
            <p className="h5 text-center m-sm-2 px-sm-3">
              CONSULADVISORS LLC advises with forward-looking business strategy
              consulting services.
            </p>
            <br />
            <p className="h5 text-center m-sm-3">
              Helping drive sustainable competitive advantage and profitability.
            </p>
            <br />
            <br />
          </div>
        </div>
      </div>
      <div className="text-center text-white my-5">
        <h2 className="my-4">Driving organizational growth</h2>
        <p className="h4 m-5">
          We are committed to fully understand the scope, timing, duration and
          outcomes of projects based on your priorities.
        </p>
      </div>
      <div className="row">
        <div
          className="imgVidHeight col-md-3 bg-image-cropped"
          style={{ backgroundImage: 'url(".//media/office.jpeg")' }}
        />
        <div ref={centerText} className="col-md-6 text-center ">
          <h3 className="my-4 px-5">
            CONSULADVISORS LLC delivers transformative results
          </h3>
          <p className="my-4 px-5">
            Now in the Fourth Industrial Revolution business became more complex
            and global. Digital transformation certainly brings a cultural shift
            that requires businesses to think about new solutions to begin a
            journey within the entire organization embracing change to
            revolutionize the way in which true value is delivered to customers.
            It starts by aligning your strategy to your business mission.
          </p>
          <p className="my-4 px-5">
            We bring objectivity, as consultants we will contribute with dynamic
            new approaches to your business needs, by offering an outside
            perspective. Delivering an understanding of how other clients have
            approached similar problems, helping businesses to set the right
            goals and milestones for progress and the specific results that will
            equate to a successful outcome on our projects. Bringing
            specialized, market-leading skill sets as well as knowledge of
            market-leading technology. We provide proven results by using
            process-oriented and method-driven delivery practices, like agile
            business processes, eliminating unneeded processes and artificial
            boundaries to fuel a culture of collaboration, helping to focus on
            what matters most to clients.
          </p>
        </div>
        <div
          className="imgVidHeight col-md-3 bg-image-cropped"
          style={{
            backgroundImage: 'url("./media/digitalConsuladvisors.png")',
          }}
        />
      </div>
      <div className="row">
        <div className="col-md">
          <div className="card-body m-md-5"></div>
          <div
            ref={card}
            className="card-body h4 text-center text-white rounded-3 m-5"
          >
            Guidance in strategic evaluation on corporate decisions.
          </div>
          <div
            className="bg-image-cropped m-3"
            style={{
              backgroundImage: 'url("./media/team.jpg")',
              height: "700px",
            }}
          />
        </div>
        <div className="col-md">
          <br />
          <div className="video-container" style={{ height: "450px" }}>
            {!mouseOverState && (
              <video
                src={videoSrc2}
                autoPlay={true}
                muted={true}
                loop={true}
                onMouseOver={mouseOverHandler}
              />
            )}
            {mouseOverState && (
              <div
                ref={card3}
                className="text-center h3 h-100 m-2"
                onMouseOut={mouseOutHandler}
              >
                <br />
                <br />
                <br />
                <div>consuladvisors</div>
                <div className="border border-1 border-dark m-4" />
              </div>
            )}
          </div>
          <div ref={card2} className="card-body rounded-3 m-5">
            <div className="my-5">
              <div className="border border-5 border-dark w-25 mx-3" />
              <div className="border border-1 border-dark w-75 mx-3" />
              <h3 className="display-6 m-3">
                Our goal: empower the right path to success
              </h3>
              <br />
              <p className="text-center h4 m-2">
                We aim to help our clients to design strategies and define
                vision to achieve expected results
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
