import React, { useState, useRef } from "react";

import { useForm } from "../../shared/hooks/form-hook";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
} from "../util/validators";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ReCAPTCHA from 'react-google-recaptcha';
import emailService from '../../servicios/email';
import "./ContactForm.css"



const ContactForm = () => {
  const [captachValido, cambiarCaptchaValido] = useState(null);
  const [usuarioValido, cambiarUsuarioValido] = useState(false);
  const [envio, setEnvio] = useState(false);


  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      message: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const captcha = useRef(null);

  const onChange = () => {
    if (captcha.current.getValue()) {
      console.log("El usuario no es un robot");
      cambiarCaptchaValido(true);
    };
  }

  /*
    const placeSubmitHandler = async (event) => {
      event.preventDefault();
      try {
        const body = {
          "to": "fordonez@telavog.net",
          "subject": "Mensaje desde página web",
          "text": "Nuevo mensaje desde forma de contacto de la página web de: " +
          formState.inputs.name.value +
          ".\n" +
          "Email de contacto: " +
          formState.inputs.email.value +
          "\n" +
          "Mensaje: " +
          formState.inputs.message.value
        };
        
        const formData = new FormData();
        formData.append("to", "fordonez@telavog.net");
        formData.append("subject", "Mensaje desde página web");
        formData.append(
          "text",
          "Nuevo mensaje desde forma de contacto de la página web de: " +
            formState.inputs.name.value +
            ".\n" +
            "Email de contacto: " +
            formState.inputs.email.value +
            "\n" +
            "Mensaje: " +
            formState.inputs.message.value
        );
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/sendEmail",
          "POST",
          JSON.stringify(body),
          {
            "Content-Type": "application/json",
          }
        );
        //history.push("/");
      } catch (error) {}
    };*/


  const placeSubmitHandler = async (event) => {
    event.preventDefault();
    //console.log(formState.inputs); // send this to the backend!
    const newEmail = {
      nombre: formState.inputs.name.value,
      correo: formState.inputs.email.value,
      mensaje: formState.inputs.message.value
    }




    if (captcha.current.getValue()) {
      console.log("El usuario no es un robot");
      cambiarUsuarioValido(true);
      cambiarCaptchaValido(true);
      setEnvio(true);

      if (formState.inputs.name.isValid &&
        formState.inputs.email.isValid &&
        formState.inputs.message.isValid) {

        emailService.send_mail(newEmail)
      }




    } else {
      console.log("Por favor acepta el captcha");
      cambiarUsuarioValido(false);
      cambiarCaptchaValido(false);
    }


  };


  return (
    <>
      <form className="place-form" onSubmit={placeSubmitHandler}>
        <Input
          id="name"
          element="input"
          type="text"
          label="Name"
          validators={[VALIDATOR_REQUIRE]}
          errorText="Please enter a valid name."
          onInput={inputHandler}
        />
        <Input
          id="email"
          element="input"
          type="text"
          label="Email"
          validators={[VALIDATOR_EMAIL(), VALIDATOR_REQUIRE]}
          errorText="Please enter a valid email address."
          onInput={inputHandler}
        />
        <Input
          id="message"
          element="textarea"
          type="text"
          label="Message"
          validators={[VALIDATOR_MINLENGTH(10), VALIDATOR_REQUIRE]}
          errorText="Please enter a valid message (at least 10 characters)."
          onInput={inputHandler}
        />

        <div>
          <ReCAPTCHA
            ref={captcha}
            sitekey='6LdKzt4hAAAAAKpL6qAW4paHojK4H4TaOdbGyaXx'
            onChange={onChange}
          />
        </div>



        <br />
        <Button type="submit" disabled={!formState.isValid && !usuarioValido}>
          SEND MESSAGE
        </Button>

        <div className="mt-3">

        {captachValido === false && <div className='error-captcha white-text'> Por favor acepta el captcha</div>}
        {envio === true && <div className='error-captcha white-text'> Se envió el correo</div>}

        </div>

      </form>
    </>
  );
};

export default ContactForm;
//<ReCAPTCHA sitekey="6LefGeAfAAAAAD8BXk5cfNBv_dLb7ZiJBe4JqRUh" />
