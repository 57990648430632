import React from "react";

import ContactForm from "./shared/components/ContactForm";
import TwitterFeed from "./shared/components/TwitterFeed";
import { Timeline } from 'react-twitter-widgets'

const Foot = () => {
  return (
    <>
      <br />
      <div className="mx-auto row">
        <div className="col">
          <div className="text-center text-white m-1 py-3">
            <p className="h5 text-secondary">Follow us on X</p>
            <a href="https://twitter.com/consuladvisors" className="h5">@consuladvisors</a>
          </div>
        </div>
        <div className="col">
          <div className="text-center">
            <div>
              <br />
              <img
                className="rounded-3 w-50"
                alt="Greater Miami Chamber ConsulAdvisors"
                src="./media/greatMiamiChamber.jpeg"
              />
            </div>
            <br />
            <div>
              <img
                className="rounded-3 w-50"
                alt="Connect Americas"
                src="./media/connectAmericas.jpg"
              />
            </div>
          </div>
          <br />
          <br />
          <div
            className="h4 text-center text-white"
            style={{ textColor: "#000000" }}
          >
            CONTACT US
          </div>
          <div className="text-white m-5">
            Address:{" "}
            <p className="text-secondary px-3">
              17501 Biscayne Blvd, Suite 510, Aventura, Florida, 33160
              <br />
              United States of America
            </p>
            Phone: <p className="text-secondary px-3">+1 (786) 753 7496</p>
            Email:{" "}
            <p className="text-secondary px-3">info@consuladvisors.com</p>
            <br />
          </div>
        </div>
      </div>
      <div>
        <div
          className="h4 text-center text-white"
          style={{ textColor: "#000000" }}
        >
          Leave us a message
        </div>
        <div className="w-75 mx-auto">
          <ContactForm />
        </div>
      </div>
      <div className="text-center p-5 m-3 text-white">
        ©2022. ConsulAdvisors, LLC
      </div>
    </>
  );
};

export default Foot;
