import React from "react";

const Us = () => {
  return (
    <>
      <div className="bg-white">
        <img
          className="mx-auto d-block py-5 img-fluid"
          alt="Digital Transformation ConsulAdvisors"
          src="./media/believes.jpeg"
        />
      </div>
      <div
        className="row bg-image-cropped"
        style={{ backgroundImage: 'url(".//media/abstractbuildings2.jpeg")' }}
      >
        <div className="col-6"></div>
        <div className="col-4">
          <p className="m-5">
            CONSULADVISORS LLC provides industry-specific strategy consulting
            for fundamental challenges such as market assessment, scenario
            planning, strategic planning process design, change management,
            alliance management, logistics & supply chain, M&A, global
            procurement, digital transformation.
            <br />
            <br />
            <br />
            We advise our customers making their business evolve and thrive in their projects, our
            team has more than 25 years of experience in Strategic Consulting.
            We serve our clients across a wide range of industries, each having
            their own business ecosystem.
          </p>
        </div>
        <div className="col-1"></div>
      </div>
    </>
  );
};

export default Us;
