import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Foot from "./Foot";
import Home from "./Home";

import Menu from "./Menu";
import Us from "./Us";

const App = () => {
  document
    .getElementById("root")
    .setAttribute("style", "background-color:#1E2335");
  return (
    <BrowserRouter>
      <main>
        <Menu />
        <Routes>
          <Route index element={<Home />} />
          <Route path="us" element={<Us />} />
          <Route
            path="*"
            element={<div style={{ marginLeft: "15%" }}>PAGE NOT FOUND</div>}
          />
        </Routes>
        <Foot id="contactAnchor" />
      </main>
    </BrowserRouter>
  );
};

export default App;
